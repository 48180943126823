<template>
    <div>
        <template v-if="results.isPending">
            <div class="flex flex-col gap-2">
                <MatchSkeleton
                    v-for="index in 8"
                    :key="index"
                />
            </div>
        </template>

        <!-- Error -->
        <template v-else-if="results.isError">
            <div class="m-auto mt-20 text-center font-medium">
                Oops sorry something went wrong please refresh the page
            </div>
        </template>

        <!-- Results -->
        <template v-else-if="matchList?.length">
            <TransitionGroup
                name="list"
                tag="div"
                class="flex w-full flex-col"
            >
                <div
                    v-for="(match, matchIdx) in matchList"
                    :key="match.id"
                    class="pb-2"
                >
                    <Match
                        v-if="readonly && session.getSelectedMatch.id === match.id ? false : true"
                        :match="match"
                        :matchIndex="matchIdx"
                        :readonly="readonly"
                    />
                    <template v-if="matchIdx === 2 && moveStore.isInterstate && !isSelfPack">
                        <div
                            class="relative mt-2 flex h-[232px] flex-col overflow-clip rounded-lg border md:h-[139px] md:flex-row"
                        >
                            <div class="w-full0 absolute h-full">
                                <div class="relative p-6">
                                    <div class="flex flex-col gap-3">
                                        <div class="flex flex-col gap-2">
                                            <span class="text-xl font-medium">Need help choosing an option?</span>
                                            <span class="text-sm">
                                                Speak to a move specialist to get more information.
                                            </span>
                                        </div>
                                        <a
                                            v-if="!moveStore.isCallbackRequested"
                                            class="cursor-pointer text-base text-brand hover:underline"
                                            @click="handleRequestCallback"
                                        >
                                            Request a callback
                                        </a>
                                        <div
                                            v-else
                                            class="flex items-center gap-1 text-base text-muval-gray-1"
                                        >
                                            Callback requested
                                            <div
                                                class="flex h-4 w-4 items-center justify-center rounded-full bg-muval-gray-1"
                                            >
                                                <SvgIcon
                                                    class="text-white"
                                                    name="system-check"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <img
                                class="ml-auto hidden md:min-h-[139px] xl:flex"
                                src="/support/Desktop.png"
                            />
                            <img
                                class="ml-auto hidden md:flex md:min-h-[139px] xl:hidden"
                                src="/support/Tablet.png"
                            />
                            <img
                                class="ml-auto mt-auto h-2/3 w-auto sm:h-5/6 md:hidden"
                                src="/support/Mobile.png"
                            />
                        </div>
                    </template>
                </div>
            </TransitionGroup>
        </template>

        <!-- No results -->
        <div
            v-else
            class="flex flex-col px-5 text-muval-gray-2"
        >
            <lottie-player
                src="/no-matches.json"
                class="m-auto mt-0 h-56 w-1/2 md:mt-10"
                background="transparent"
                speed="1"
                loop
                autoplay
            ></lottie-player>

            <div class="mx-auto flex w-full flex-col">
                <span class="text-lg font-medium text-muval-gray-1">
                    We're still working on finding options for your move.
                </span>
                <span class="mt-2 text-left text-sm text-muval-gray-2">
                    Some locations and dates can take slightly longer to find availability for. Rest assured that our
                    team are working on it and can find a solution from our network of over 100 pre-screened and
                    verified service partners. We'll be in touch with you shortly.
                </span>
                <span class="mt-4 text-sm">
                    Can't wait? Call
                    <a
                        href="tel:1300168825"
                        class="font-medium text-brand hover:underline"
                    >
                        1300 168 825
                    </a>
                    to speak to a move specialist now
                    <span v-show="slaask.show">
                        or chat with us
                        <a
                            class="cursor-pointer text-brand"
                            @click="slaask.toggleChat"
                        >
                            online
                        </a>
                    </span>
                    .
                </span>

                <div class="">
                    <div
                        v-if="!moveStore.isCallbackRequested"
                        class="mt-4 text-base"
                    >
                        Alternatively
                        <a
                            class="cursor-pointer text-brand hover:underline"
                            @click="handleRequestCallback"
                        >
                            request a callback.
                        </a>
                    </div>
                    <div
                        v-else
                        class="mt-4 flex items-center gap-1 text-base text-success"
                    >
                        Callback requested
                        <div class="flex h-4 w-4 items-center justify-center rounded-full bg-success">
                            <SvgIcon
                                class="text-white"
                                name="system-check"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ProductableType } from '@/types';
import Match from './Match.vue';
import { useResultsStore, useSessionStore, useAppStore, useMoveStore } from '@/store';

const props = defineProps<{
    readonly?: Boolean;
    isSelfPack?: Boolean;
}>();

const { slaask } = useAppStore();

const results = useResultsStore();
const session = useSessionStore();
const moveStore = useMoveStore();

const matchList = computed(() => (props.isSelfPack ? results.selfPackMatchList : results.organicMatchList));

const handleRequestCallback = () => {
    session.requestCallback({ productable_type: ProductableType.MOVE });
};
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}
</style>
