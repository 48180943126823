<template>
    <div class="relative flex w-full font-body md:overflow-x-hidden">
        <form
            data-testid="insurance-form"
            class="flex w-full flex-col md:pr-4"
            @submit.prevent="onSubmit"
        >
            <div class="flex w-full flex-col gap-5 md:flex-row">
                <div class="flex w-full flex-col md:w-1/2">
                    <InsuranceOptions
                        v-if="insuranceStore.serverCategories && insuranceStore.serverCategories.length > 0"
                        v-model:searchInput="searchInput"
                    />
                </div>
                <div class="flex w-full flex-col gap-2 md:w-1/2">
                    <div class="relative flex w-full flex-col gap-5 rounded-lg bg-white p-4 shadow">
                        <h4>{{ sessionStore.getInsurancePolicyBrand }} breakdown</h4>

                        <div class="relative grid divide-y rounded-lg border">
                            <div class="flex items-center justify-between px-3 py-2">
                                <span class="font-medium"
                                    >{{ $filters.capitalize(sessionStore.getInsurancePolicyVerbPast) }} inventory
                                    value</span
                                >
                                <span v-text="insuranceStore.getInsuredInventoryValue" />
                            </div>
                        </div>

                        <div
                            v-if="sessionStore?.getSelectedMatch?.match_type == 'MUVEXPRESS'"
                            class="relative grid divide-y rounded-lg border"
                        >
                            <div class="flex items-center justify-between px-3 py-2">
                                <span class="font-medium">MuvExpress included cover</span>
                                <span v-text="insuranceStore.getMuvexpressCover" />
                            </div>
                            <div class="flex items-center justify-between px-3 py-2">
                                <span class="font-medium">Gap amount</span>
                                <span v-text="insuranceStore.getMuvexpressGapAmount" />
                            </div>
                        </div>

                        <div class="relative grid divide-y rounded-lg border">
                            <div class="flex items-center justify-between px-3 py-2">
                                <span class="font-medium">Excess</span>
                                <span v-text="insuranceStore.getExcess" />
                            </div>
                            <div class="flex items-center justify-between px-3 py-2">
                                <span class="font-medium">{{ sessionStore.getInsurancePolicyPremiumLabel }}</span>
                                <span v-text="insuranceStore.getPremium" />
                            </div>
                            <div
                                v-if="!isCreate"
                                class="flex items-center justify-between px-3 py-2"
                            >
                                <span class="font-medium"
                                    >Paid<sup
                                        v-if="sessionStore.getPrimaryProduct?.status === 'PENDING'"
                                        class="text-muval-gray-2"
                                        >*</sup
                                    ></span
                                >
                                <span v-text="getAmountPaid" />
                            </div>
                        </div>
                        <span
                            v-if="!isCreate && sessionStore.getPrimaryProduct.status === 'PENDING'"
                            class="-mt-3 ml-auto whitespace-nowrap text-xs text-muval-gray-3"
                        >
                            *Paid amount will be charged when removalist accepts
                        </span>

                        <div
                            v-if="!isCreate"
                            class="relative grid divide-y rounded-lg border"
                        >
                            <div class="flex items-center justify-between px-3 py-2">
                                <span class="font-medium">Amount outstanding</span>
                                <span v-text="getAmountOwingFormatted" />
                            </div>
                        </div>

                        <span class="-mt-3 ml-auto text-xs text-muval-gray-3">All prices include GST</span>
                        <MuvalBanner
                            v-if="
                                (getInsuranceItemProduct?.order_item_status !== 'UNCONFIRMED' && getAmountOwing > 0) ||
                                (getInsuranceItemProduct?.order_item_status === 'UNCONFIRMED' &&
                                    getAmountOwing > 0 &&
                                    sessionStore.hasMoveBeenAccepted)
                            "
                            type="info"
                            >Due to the increase of total cover to {{ insuranceStore.getInsuredInventoryValue }}, an
                            additional payment of {{ getAmountOwingFormatted }} will be applied to your order
                        </MuvalBanner>

                        <template v-if="insuranceStore.isEditable">
                            <div class="flex flex-col gap-1">
                                <div class="flex gap-2">
                                    <Field
                                        id="confirmed_documents"
                                        name="confirmed_documents"
                                        type="checkbox"
                                        :value="true"
                                        class="mt-1 h-4 w-4 cursor-pointer rounded-muval-1 border-muval-gray-3 text-black focus:outline-none focus:ring-0"
                                    />
                                    <label
                                        for="confirmed_documents"
                                        class="cursor-pointer select-none text-sm"
                                    >
                                        I/we have received, read and understood a copy of the
                                        <a
                                            :href="insuranceStore.getFiles[0].url"
                                            target="_blank"
                                            class="link"
                                            >{{ sessionStore.getInsurancePolicyDocumentLabel }}</a
                                        >
                                        and accept the option elected.
                                    </label>
                                </div>
                                <div class="flex gap-2">
                                    <Field
                                        id="confirmed_goods"
                                        name="confirmed_goods"
                                        type="checkbox"
                                        :value="true"
                                        class="mt-1 h-4 w-4 cursor-pointer rounded-muval-1 border-muval-gray-3 text-black focus:outline-none focus:ring-0"
                                    />
                                    <label
                                        for="confirmed_goods"
                                        class="cursor-pointer select-none text-sm"
                                    >
                                        I/we confirm these are domestic goods and they are not coming out of storage.
                                    </label>
                                </div>
                                <div class="flex gap-2">
                                    <Field
                                        id="confirmed_vehicles"
                                        name="confirmed_vehicles"
                                        type="checkbox"
                                        :value="true"
                                        class="mt-1 h-4 w-4 cursor-pointer rounded-muval-1 border-muval-gray-3 text-black focus:outline-none focus:ring-0"
                                    />
                                    <label
                                        for="confirmed_vehicles"
                                        class="cursor-pointer select-none text-sm"
                                    >
                                        I/we confirm that no motor vehicles are attempted to be covered.
                                    </label>
                                </div>
                                <div
                                    v-if="tickErrorMessage"
                                    class="flex rounded-muval-2 bg-muval-red-2 px-2 py-2 text-sm text-error"
                                >
                                    {{ tickErrorMessage }}
                                </div>
                            </div>
                        </template>
                        <div
                            v-if="calculationLoading"
                            class="absolute inset-0 flex h-full w-full"
                        >
                            <div
                                class="relative flex h-full w-full flex-col"
                                :class="{
                                    'bg-white/50': calculationLoading,
                                }"
                            >
                                <MuvalLoader
                                    v-if="calculationLoading"
                                    class="mt-32 h-24 transition-opacity"
                                    :class="calculationLoading ? 'opacity-100' : 'pointer-events-none opacity-0'"
                                />
                            </div>
                        </div>
                    </div>
                    <MuvalBanner
                        v-if="errorMessage"
                        class="mt-4"
                        type="warning"
                        icon="system-information"
                        inline
                    >
                        {{ errorMessage }}
                    </MuvalBanner>
                    <MuvalBanner
                        v-if="!insuranceStore.isEditable"
                        class="mt-4"
                        type="warning"
                        icon="system-information"
                        inline
                    >
                        Your {{ sessionStore.getInsurancePolicyBrand }} is unable to be edited. Please contact Muval for
                        support.
                    </MuvalBanner>
                    <div class="form-save">
                        <MuvalButton
                            type="button"
                            quaternary
                            borderless
                            @click="closeInsuranceDialog(insuranceStore.isEditable)"
                        >
                            {{ !insuranceStore.isEditable ? 'Close' : 'Cancel' }}
                        </MuvalButton>
                        <MuvalButton
                            v-if="insuranceStore.isEditable"
                            alternate
                            :disabled="
                                !insuranceStore.isEligible ||
                                !form.meta.valid ||
                                calculationLoading ||
                                !insuranceStore.isEditable
                            "
                            :loading="form?.isSubmitting"
                        >
                            {{ `${isCreate ? 'Add ' : 'Save '}${sessionStore.getInsurancePolicyBrand}` }}
                        </MuvalButton>
                    </div>
                    <CommonDisclosure
                        v-if="insuranceStore.isEditable && sessionStore.getInsurancePolicyType == 'WARRANTY'"
                        has-border
                        title="Important information"
                        class="mb-1 mt-4"
                        :default-open="false"
                    >
                        <div class="flex flex-col gap-1 text-sm">
                            <span class="font-medium">Duty of Disclosure</span>
                            <span>
                                Before you purchase the Muval Cover product, you have a duty to disclose to us every
                                matter that you know, or could reasonably be expected to know, that is relevant to our
                                decision on whether to cover you and, if so, on what terms.
                                <br />
                            </span>
                            <span class="font-medium">Non-disclosure</span>
                            <span>
                                If you fail to comply with your duty of disclosure we may be entitled to reduce our
                                liability under the product in respect of a claim or, in certain circumstances, we may
                                cancel the product altogether.
                            </span>
                            <span class="font-medium">Insufficient cover</span>
                            <span>
                                By purchasing this product, it is acknowledged that you are required to cover your items
                                for their full replacement value or maximum potential risk. In the event of a claim, if
                                an item is under-valued resulting in insufficient cover, the claimable value for that
                                item may be reduced by the percentage to which it was under-valued.
                            </span>
                        </div>
                    </CommonDisclosure>
                    <CommonDisclosure
                        v-else-if="insuranceStore.isEditable && sessionStore.getInsurancePolicyType == 'INSURANCE'"
                        has-border
                        title="Important information"
                        class="mb-1 mt-4"
                    >
                        <div class="flex flex-col gap-1 text-sm">
                            <span class="font-medium">Duty of Disclosure</span>
                            <span>
                                Before you enter into a contract of general insurance with us, you have a duty, under
                                both the Insurance Contracts Act 1984 and the Marine Insurance Act 1909, to disclose to
                                us every matter that you know, or could reasonably be expected to know, that is relevant
                                to our decision on whether to insure you and, if so, on what terms.
                                <br />
                                You have the same duty of disclosure to us when you renew, extend, vary or reinstate a
                                contract of general insurance.
                                <br />
                                Your duty however does not require disclosure of matters:
                                <br />
                            </span>
                            <ul class="list-disc pl-4">
                                <li>That diminishes the risk</li>
                                <li>That is of common knowledge</li>
                                <li>
                                    That we know or should know in the ordinary course of our business as an insurer
                                </li>
                                <li>Which we indicate we do not want to know</li>
                            </ul>
                            <span class="font-medium">Non-disclosure</span>
                            <span>Where the Marine Insurance Act 1909 applies:</span>
                            <span>
                                If you fail to comply with your duty of disclosure, we may void the contract of
                                insurance from its beginning. Where the Insurance Contracts Act 1984 applies: If you
                                fail to comply with your duty of disclosure we may be entitled to reduce our liability
                                under the contract in respect of a claim or, in certain circumstances, we may cancel the
                                contract. If your non-disclosure is fraudulent, we may also have the option of avoiding
                                the contract from it’s beginning.
                            </span>
                            <span class="font-medium">Underinsurance</span>
                            <span>
                                We require you to insure for the full value or maximum potential risk. If you do not do
                                so, and you are underinsured, we may pay you less in the event of a claim, calculated in
                                accordance with either the policy wording or the Marine Insurance Act 1909 where
                                applicable, which take into account the degree of underinsurance.
                            </span>
                            <span class="font-medium">Privacy information from QBE</span>
                            <span>
                                QBE includes information about how we manage your personal information in our Product
                                Disclosure Statements and policy booklets. You can obtain a copy of the QBE Privacy
                                Policy Statement from our website www.qbe.com.au or contact the Compliance Manager on 02
                                9375 4656 or email compliance.manager@qbe.com for further information.
                            </span>
                        </div>
                    </CommonDisclosure>
                </div>
            </div>
        </form>
    </div>
</template>
<script setup>
import { useSessionStore, useInsuranceStore } from '@/store';
import { openInsurancePaymentDialog, closeInsuranceDialog } from '@/composables/dialog';
import { Field, useForm, useFieldError } from 'vee-validate';
import { debouncedWatch } from '@vueuse/core';
import Dinero from 'dinero.js';
import InsuranceOptions from './InsuranceOptions.vue';
import { toTypedSchema } from '@vee-validate/valibot';
import { pipe, number, object, maxValue, array, boolean, union, string, minLength } from 'valibot';

const sessionStore = useSessionStore();
const insuranceStore = useInsuranceStore();

const validationSchema = toTypedSchema(
    object({
        inventory: array(
            union([
                object({
                    inventory_option_id: number(),
                    qty: pipe(number(), maxValue(1000)),
                    value: pipe(number(), maxValue(100000000)),
                }),
                object({
                    inventory_option_label: pipe(string(), minLength(1)),
                    qty: pipe(number(), maxValue(1000)),
                    value: pipe(number(), maxValue(100000000)),
                }),
            ]),
        ),
        excess: number(),
        confirmed_documents: boolean(),
        confirmed_goods: boolean(),
        confirmed_vehicles: boolean(),
    }),
);

const form = reactive(
    useForm({
        keepValuesOnUnmount: true,
        validationSchema: validationSchema,
    }),
);
const isCreate = computed(() => {
    return !sessionStore.getInsuranceItemProduct?.id;
});
const searchInput = ref('');
const tickErrorMessage = ref();

const savedValues = computed(
    () =>
        sessionStore.getInsuranceItemProduct.latest_premium.inventory?.map((selectedInventory) => {
            return {
                ...(selectedInventory.inventory_option_id ?
                    { inventory_option_id: selectedInventory.inventory_option_id }
                :   { inventory_option_label: selectedInventory.inventory_option_label }),
                qty: selectedInventory.qty,
                value: selectedInventory.value ?? 0,
            };
        }) ?? [],
);

const errorMessage = useFieldError('policy');

onMounted(async () => {
    await insuranceStore.init();
    if (sessionStore.getInsuranceItemProduct?.id) {
        isCreate.value = false;

        form.resetForm({
            values: {
                inventory: savedValues.value,
                excess: 50000,
            },
        });
    } else {
        isCreate.value = true;
        form.resetForm({
            values: {
                inventory: [],
                excess: 50000,
            },
        });
    }
});

const loading = ref(false);

const filteredInventory = computed(() => {
    // reduce inventory to only include values where value is greater than 0
    return form.values?.inventory?.filter(
        (item) => item.value > 0 && item.qty > 0 && (item.inventory_option_id || item.inventory_option_label),
    );
});

const totalDollarAmountOfInventory = computed(() => {
    // add up all inventory value
    return (
        filteredInventory?.value?.reduce((acc, item) => {
            return acc + item.value;
        }, 0) ?? 0
    );
});

const totalQtyAmountOfInventory = computed(() => {
    // add up all inventory value
    return (
        filteredInventory?.value?.reduce((acc, item) => {
            return acc + item.qty;
        }, 0) ?? 0
    );
});

const onSubmit = form.handleSubmit(async (values) => {
    if (form.isSubmitting.value) {
        return;
    }

    try {
        if (!values.confirmed_documents || !values.confirmed_goods || !values.confirmed_vehicles) {
            tickErrorMessage.value = 'Please tick the boxes above.';
        } else {
            let insurance = null;

            loading.value = true;

            if (isCreate.value) {
                insurance = await insuranceStore.createInsurance({
                    inventory: filteredInventory.value,
                    excess: values.excess,
                    confirmed_documents: values.confirmed_documents,
                    confirmed_goods: values.confirmed_goods,
                    confirmed_vehicles: values.confirmed_vehicles,
                });
            } else {
                insurance = await insuranceStore.updateInsurance({
                    inventory: filteredInventory.value,
                    excess: values.excess,
                    confirmed_documents: values.confirmed_documents,
                    confirmed_goods: values.confirmed_goods,
                    confirmed_vehicles: values.confirmed_vehicles,
                });
            }

            loading.value = false;

            closeInsuranceDialog(false);

            // only open payment modal if immediate payment and a premium to be paid
            // and the primary item is confirmed
            // and it is not a corporate relocation
            if (
                (sessionStore.getPrimaryItem?.status === 'CONFIRMED' || sessionStore.isPendingBankDeposit) &&
                insurance &&
                !['PENDING', 'ACCEPTED'].includes(insurance.status) &&
                insurance.amount_owing.amount > 0
            ) {
                if (sessionStore.isRelocation) {
                    if (sessionStore.getInsuranceItem?.totals?.BALANCE?.amount > 0) {
                        openInsurancePaymentDialog();
                    } else {
                        await sessionStore.requestApproval([{ id: sessionStore.getInsuranceItem?.id }]);
                    }
                } else {
                    openInsurancePaymentDialog();
                }
            }
        }
    } catch (error) {
        console.log('error', error);
    }
});

const calculationLoading = ref(false);

watch(form.values, () => {
    tickErrorMessage.value = null;
});

debouncedWatch(
    [totalDollarAmountOfInventory, totalQtyAmountOfInventory],
    async () => {
        // if (totalDollarAmountOfInventory.value == 0) {
        //     return;
        // }

        try {
            form.setErrors({});

            calculationLoading.value = true;

            await insuranceStore.calculate(filteredInventory.value, 50000);

            calculationLoading.value = false;
        } catch (error) {
            if (error.errors) {
                form.setErrors(error.errors);
            }

            calculationLoading.value = false;
        }
    },
    {
        immediate: true,
        deep: true,
        debounce: 1000,
    },
);

//// Additional payments after the first payment
const getInsuranceItemProduct = computed(() => {
    return sessionStore?.getInsuranceItemProduct;
});

const getAmountPaid = computed(() => {
    return getInsuranceItemProduct?.value?.amount_paid?.formatted ?? null;
});

const getAmountOwing = computed(() => {
    if (insuranceStore.calculation && getInsuranceItemProduct?.value?.amount_paid) {
        return insuranceStore.calculation.premium.amount - getInsuranceItemProduct?.value?.amount_paid?.amount;
    }

    return null;
});

const getAmountOwingFormatted = computed(() => {
    if (insuranceStore.calculation && getInsuranceItemProduct?.value?.amount_paid) {
        let amountOwing = Dinero({
            amount: insuranceStore.calculation.premium.amount - getInsuranceItemProduct?.value?.amount_paid?.amount,
        });

        if (amountOwing.getAmount() < 0) {
            amountOwing = Dinero({ amount: 0 });
        }

        return amountOwing.toFormat('$0,0.00');
    } else {
        return '$0.00';
    }
});

// Clear calculated values on unmount
onUnmounted(() => {
    insuranceStore.calculation = null;
});
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
