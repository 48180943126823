/**
 * Vue Router
 *
 * @package Muval Book Project
 * @copyright 2022 Muval Pty Ltd. All rights reserved.
 * @author Tim Maier
 */

import { START_LOCATION, createRouter, createWebHistory } from 'vue-router';

import { useAppStore, useFeedbackStore, useSessionStore } from '@/store';
import stepRoutes from './stepRoutes';
import resultRoutes from './resultRoutes';
import feedbackRoutes from './feedbackRoutes';

import { useTitle, useUrlSearchParams } from '@vueuse/core';
import posthog from 'posthog-js';
import { openVideoDialog } from '@/composables/dialog';

const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN;

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
            };
        }
        // if page is refreshed, scroll to saved position
        if (savedPosition && !from.name) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
    history: createWebHistory(),
    routes: [
        {
            name: 'CatchAll',
            path: '/:pathMatch(.*)*',
            beforeEnter: async (to) => {
                console.log('🔂 no routes matched, catch all triggered');

                const session = useSessionStore();

                // do not remove - this is required to route a session back to the latest route
                if (session.hasSession && session.isReady) {
                    // routing to saved session route
                    console.log(`➡️ routing to saved session route ${session.getRoute}`);

                    return session.getRoute;
                }

                // do not remove - this is required to route an empty path to the start
                if (!session.hasSession && !to.params.pathMatch) {
                    // session and path empty, clearing session and navigating to start
                    console.log('➡️ session and path empty, clearing session and navigating to start');

                    session.reset(null);

                    return '/step/pickup_details/pickup_address';
                }

                // do not remove - this is to match pages that are not defined

                // route not defined, navigating to not found
                console.log('➡️ route not defined, navigating to not found');

                return '/not-found';
            },
        },
        {
            name: 'AddInventory',
            path: '/add/inventory',
            component: () => import('@/views/AddInventory.vue'),
            meta: {
                title: 'Add Inventory',
                requireSession: true,
            },
        },
        {
            name: 'PaymentPage',
            path: '/payment',
            component: () => import('@/views/PaymentPage.vue'),
            meta: {
                title: 'Payment',
                requireSession: true,
            },
        },
        {
            name: 'Preferences',
            path: '/preferences',
            component: () => import('@/views/Subscriptions.vue'),
            meta: {
                title: 'Preferences',
                requireSession: true,
            },
        },
        {
            name: 'PageNotFound',
            path: '/not-found',
            meta: {
                title: 'Page Not Found',
            },
            component: () => import('@/views/PageNotFound.vue'),
        },
        {
            name: 'ServerError',
            path: '/server-error',
            meta: {
                title: 'Server Error',
            },
            component: () => import('@/views/ServerError.vue'),
        },
        {
            name: 'SessionExpired',
            path: '/session-expired',
            meta: {
                title: 'Session Expired',
            },
            component: () => import('@/views/SessionExpired.vue'),
        },
        {
            name: 'SessionForbidden',
            path: '/session-forbidden',
            component: () => import('@/views/SessionForbidden.vue'),
        },
        {
            name: 'Maintenance',
            path: '/maintenance',
            meta: {
                title: 'Maintenance',
                fullscreen: true,
            },
            component: () => import('@/views/Maintenance.vue'),
        },
        {
            name: 'ContactMuval',
            path: '/contact-muval',
            meta: {
                title: 'Contact Muval',
            },
            component: () => import('@/views/ContactMuval.vue'),
        },
        {
            name: 'NewSession',
            path: '/new/:bookingType?',
            props: true,
            component: () => import('@/pages/NewSession.vue'),
            beforeEnter: async (to, from) => {
                const session = useSessionStore();

                const bookingType = to.params.bookingType;
                session.reset(bookingType);

                const preselect = to.query.preselect;
                session.setPreselect(preselect);

                return {
                    path: '/step/pickup_details/pickup_address',
                    params: from.params,
                };
            },
        },
        {
            name: 'GetSession',
            path: '/session/:orderRef/:token/:section?/:form?',
            props: true,
            component: () => import('@/pages/GetSession.vue'),
            beforeEnter: async (to) => {
                const session = useSessionStore();

                // backwards compat for session/orderRef/token/section/form URLs
                // that route to add/inventory and payment respectively
                if (to.params.section && to.params.form) {
                    return `/${to.params.section}/${to.params.form}`;
                } else if (to.params.section) {
                    return `/${to.params.section}`;
                }

                return session.getRoute;
            },
        },
        {
            path: '/step',
            component: () => import('@/pages/steps/index.vue'),
            children: [...stepRoutes],
            meta: {
                requireSession: true,
            },
            beforeEnter: async () => {
                const session = useSessionStore();

                if (session.hasConfirmed) {
                    // prevent confirmed session from accessing steps
                    console.log('⏩ prevent confirmed session from accessing steps');

                    return '/results/confirmed';
                }
            },
        },
        {
            path: '/results',
            component: () => import('@/pages/results/index.vue'),
            meta: {
                requireSession: true,
            },
            children: [...resultRoutes],
            beforeEnter: async (to) => {
                const session = useSessionStore();

                if (session.hasExpired) {
                    // prevent expired session from accessing steps
                    console.log('⏩ prevent expired session from accessing steps');

                    return '/relocation/expired';
                }

                // do not remove - this is to catch links to /results
                if (to.path === '/results') {
                    if (session.hasConfirmed) {
                        return '/results/confirmed';
                    } else if (session.hasSelectedMatch) {
                        return '/results/review';
                    } else if (session.hasRecommendedMatches) {
                        return '/results/recommended';
                    } else {
                        return '/results/organic';
                    }
                }

                return true;
            },
        },
        {
            path: '/feedback',
            component: () => import('@/pages/feedback/index.vue'),
            meta: {
                requireSession: true,
            },
            children: [...feedbackRoutes],
            beforeEnter: async (to) => {
                const feedbackStore = useFeedbackStore();
                return feedbackStore.getRouteDirection(to);
            },
        },
        {
            path: '/feedback/muval',
            name: 'FeedbackMuval',
            meta: {
                requireSession: true,
                title: 'Feedback - Muval',
            },
            component: () => import('@/pages/feedback/FeedbackMuval.vue'),
            beforeEnter: () => {
                const feedbackStore = useFeedbackStore();

                if (feedbackStore.pageNum < 3) {
                    return '/feedback';
                }
            },
        },
        {
            path: '/feedback/thankyou',
            name: 'FeedbackThankyou',
            meta: {
                requireSession: true,
                title: 'Feedback - Thank you',
            },
            component: () => import('@/pages/feedback/FeedbackThankyou.vue'),
        },
        /*
         * Relocation
         */
        {
            path: '/relocation/start',
            name: 'RelocationStart',
            meta: {
                title: 'Start your move',
                requireSession: true,
                fullscreen: true,
            },
            component: () => import('@/pages/relocations/RelocationStart.vue'),
            beforeEnter: () => {
                const session = useSessionStore();

                if (session.hasExpired) {
                    // prevent expired session from accessing steps
                    console.log('⏩ prevent expired session from accessing steps');

                    return '/relocation/expired';
                }
            },
        },
        {
            path: '/relocation/expired',
            name: 'RelocationExpired',
            meta: {
                title: 'Relocation expired',
                requireSession: true,
                fullscreen: true,
            },
            component: () => import('@/pages/relocations/RelocationExpired.vue'),
            beforeEnter: () => {
                const session = useSessionStore();

                if (!session.hasExpired) {
                    // prevent unexpired session from expired guard
                    console.log('⏩ prevent unexpired session from expired guard');

                    return '/relocation/start';
                }
            },
        },
        /*
         * Affiliate
         */
        {
            path: '/affiliate/landing',
            name: 'AffiliateLanding',
            meta: {
                title: 'Start your move',
                requireSession: true,
                fullscreen: true,
            },
            component: () => import('@/pages/affiliates/AffiliateLanding.vue'),
        },
    ],
});

router.beforeEach(async (to, from) => {
    console.log('▶️ navigation started', { path: to.path });

    const app = useAppStore();

    if (from === START_LOCATION && !app.hasReadParams) {
        console.log('🍪 storing query params in bookClick cookie 🫙', to.query);

        storeUrlParamsInCookie(to);

        app.hasReadParams = true;
    }

    // Handle video query param
    const { video } = to.query;
    if (video) {
        nextTick(() => {
            openVideoDialog(video);
        });
    }

    if (to.path === '/affiliate/landing') {
        return true;
    }

    if (to.path.match(/^\/new(\/[a-zA-Z_]+)?(\/)?$/)) {
        // new session - skip checks on current session
        console.log('🆕 new session - skip checks on current session', { path: to.path });

        return true;
    }

    const session = useSessionStore();

    const { orderRef, token } = to.params;
    const { memToken, memRef } = to.query;

    if (orderRef && token) {
        // order ref and token in params - initiating session from url params
        console.log('🏷️ order ref and token in params - initiating session from url params');

        try {
            const page = to.params?.section ? to.params.section : to.path;
            await session.init({ orderRef, token, query: to.query, page: page });
        } catch (error) {
            console.log('⛔️ unable to load session from params', error);

            if (getTerminatingError(error)) {
                // session is not recoverable, clearing session
                console.log('⛔️ session is not recoverable, clearing session', getTerminatingError(error));

                session.reset(null);

                return getTerminatingError(error);
            }
        }

        if (session.isReady) {
            // session is ready after init
            console.log('✅ session is ready after init from params');
        } else {
            // session is not ready after init
            console.log('⛔️ session is not ready after init from params');
        }
    } else if (memToken && memRef) {
        // order ref and token in query - initiating session from query string
        console.log('🏷️ order ref and token in query - initiating session from query string');

        try {
            await session.init({ orderRef: memRef, token: memToken, query: to.query, page: to.path });
        } catch (error) {
            console.log('⛔️ unable to load session from query', error);

            if (getTerminatingError(error)) {
                // session is not recoverable, clearing session
                console.log('⛔️ session is not recoverable, clearing session', getTerminatingError(error));

                session.reset(null);

                return getTerminatingError(error);
            }
        }

        if (session.isReady) {
            // session is ready after init from query
            console.log('✅ session is ready after init from query');
        } else {
            // session is not ready after init from query
            console.log('⛔️ session is not ready after init from query');
        }
    } else {
        // no order ref and token in params or query
        console.log('🟠 no order ref and token in params or query');

        if (session.hasSession) {
            // found existing session
            console.log('✅ found existing session');

            if (session.isReady) {
                // session is ready
                console.log('✅ session is ready');
            } else {
                // session is not yet ready
                console.log('⏸️ session is not yet ready');

                try {
                    await session.init();
                } catch (error) {
                    console.log('⛔️ unable to load existing session', error);

                    if (getTerminatingError(error)) {
                        // session is not recoverable, clearing session
                        console.log('⛔️ session is not recoverable, clearing session', getTerminatingError(error));

                        session.reset(null);

                        return getTerminatingError(error);
                    }
                }

                if (session.isReady) {
                    // session is ready after init from existing
                    console.log('✅ session is ready after init from existing');
                } else {
                    // session is not ready after init from existing
                    console.log('⛔️ session is not ready after init from existing');
                }
            }
        } else {
            // no session found
            console.log('🆕 no session found');

            if (to?.meta?.requireSession) {
                // session required by page, redirecting to not found
                console.log(`⛔️ session required by page ${to.path}, redirecting to not found`);

                return '/not-found';
            } else {
                // session not required by page, allowing
                console.log(`✅ session not required by page ${to.path}, allowing`);
            }
        }
    }

    await app.init();

    return true;
});

router.afterEach((to) => {
    const session = useSessionStore();
    const app = useAppStore();

    const params = useUrlSearchParams('history');
    params.memToken = session.getToken;
    params.memRef = session.getOrderRef;

    // Set meta title
    const route = router.currentRoute.value;
    useTitle(`Muval - ${route?.meta?.title ? route.meta.title : 'Moving starts here'}`);

    session.loading = false;
    app.loading = false;

    nextTick(() => {
        posthog.capture('$pageview', {
            $current_url: to.fullPath,
        });
    });

    console.log('⏹️ navigation completed', { path: to.path });
});

// Cookie utility method
function setCookie(name, value, days, path) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }

    // Use the COOKIE_DOMAIN variable for the domain
    const domain = COOKIE_DOMAIN;

    document.cookie = name + '=' + (value || '') + expires + '; path=' + (path || '/') + '; domain=' + domain;
}

function storeUrlParamsInCookie(to) {
    const paramStr = Object.entries(to.query)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

    // Set cookie
    setCookie('bookClick', paramStr, 30); // Save for 30 days
}

// check error response matches our expected ones where session is not found or forbidden
function getTerminatingError(error) {
    if (
        // this occurs if order ref not found
        error?.code === 404 &&
        (error?.message === 'No query results for model [App\\Models\\Order].' || error?.message === 'Not found.')
    ) {
        return '/not-found';
    }

    if (
        // this occurs if order ref found but token is invalid
        error?.code === 403 &&
        error?.message === 'No booking found'
    ) {
        return '/session-forbidden';
    }

    return null;
}

export default router;
