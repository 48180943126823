<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import {
    isPickupDialogOpen,
    isDeliveryDialogOpen,
    isPickupAddressDialogOpen,
    isDeliveryAddressDialogOpen,
    isDateDialogOpen,
    isSpaceDialogOpen,
    isCustomItemDialogOpen,
    isConfirmDialogOpen,
    confirmDialogLabel,
    closeConfirmDialog,
    isRequiredSpaceDialogOpen,
    isInlineCustomItemDialogOpen,
    isInlineRequiredSpaceDialogOpen,
    isUtilitiesDialogOpen,
    isInsuranceDialogOpen,
    isInsurancePaymentDialogOpen,
    isInsuranceConfirmDialogOpen,
    closeInsuranceConfirmDialog,
    isCompanyInfoDialogOpen,
    isTrustDialogOpen,
    isReadonlyMatchListDialogOpen,
    isReadonlyCompanyInfoDialogOpen,
    closeReadonlyMatchListDialog,
    isVideoDialogOpen,
    isTaxiboxDialogOpen,
    isSelfyDialogOpen,
} from '../composables/dialog';

import { useResultsStore, useSessionStore } from '@/store';

let results: any = null;

onMounted(() => {
    if (session.hasSession && session.isReady) {
        results = useResultsStore();
    }
});
const session = useSessionStore();
</script>

<template>
    <!-- Only mount these modals when the session is ready, as js called within may be running ajax requests that have dependencies on session data  -->
    <template v-if="session.hasSession && session.isReady">
        <ModalDialog
            v-model="isPickupDialogOpen"
            maxWidth="max-w-2xl max-h-[90svh] overflow-y-scroll"
        >
            <PickupDetailsForm />
        </ModalDialog>
        <ModalDialog
            v-model="isDeliveryDialogOpen"
            maxWidth="max-w-2xl max-h-[90svh]"
        >
            <DeliveryDetailsForm />
        </ModalDialog>

        <ModalDialog
            v-model="isPickupAddressDialogOpen"
            maxWidth="max-w-2xl max-h-[90svh]"
        >
            <PickupAddressForm />
        </ModalDialog>
        <ModalDialog
            v-model="isDeliveryAddressDialogOpen"
            maxWidth="max-w-2xl max-h-[90svh]"
        >
            <DeliveryAddressForm />
        </ModalDialog>

        <!-- Date Modal -->
        <ModalDialog
            v-model="isDateDialogOpen"
            maxWidth="max-w-2xl max-h-[90svh] min-h-[555px] md:min-h-[510px]"
        >
            <div class="flex flex-col gap-4">
                <span class="text-base font-medium">Edit pickup date</span>
                <DateForm />
            </div>
        </ModalDialog>

        <!-- Space Modal -->
        <!-- The overflow here is needed for safari iOS do NOT remove -->
        <ModalDialog
            v-model="isSpaceDialogOpen"
            maxWidth="relative max-w-5xl max-h-[90svh]  md:h-[800px] overflow-y-scroll md:overflow-y-auto"
        >
            <InventoryForm />

            <!-- Must be nested -->
            <ModalDialog
                v-model="isCustomItemDialogOpen"
                maxWidth="max-w-5xl"
            >
                <CustomInventoryForm />
            </ModalDialog>

            <ModalDialog
                v-model="isRequiredSpaceDialogOpen"
                maxWidth="max-w-md"
            >
                <RequiredSpaceForm />
            </ModalDialog>
        </ModalDialog>

        <!-- Inline Custom Item needed when parent is not modal -->
        <ModalDialog
            v-model="isInlineCustomItemDialogOpen"
            maxWidth="max-w-md max-h-[90svh] p-6"
        >
            <CustomInventoryForm :inline="true" />
        </ModalDialog>

        <!-- Inline Custom Item needed when parent is not modal -->
        <ModalDialog
            v-model="isInlineRequiredSpaceDialogOpen"
            maxWidth="max-w-md pt-6 px-6"
        >
            <RequiredSpaceForm :inline="true" />
        </ModalDialog>

        <ModalDialog
            v-model="isUtilitiesDialogOpen"
            maxWidth="max-w-md md:max-w-sm"
        >
            <UtilitiesDateForm />
        </ModalDialog>

        <ModalDialog
            v-model="isInsuranceDialogOpen"
            maxWidth="max-w-5xl mt-20 md:mt-0"
            :disableEscapeClose="true"
        >
            <InsuranceForm />

            <ModalDialog
                v-model="isInsuranceConfirmDialogOpen"
                maxWidth="max-w-md mt-20 md:mt-0"
                :disableEscapeClose="true"
            >
                <div class="max-w-lg">
                    <div class="flex flex-col gap-4">
                        <div class="flex flex-col gap-2">
                            <span class="font-medium">Are you sure?</span>

                            <span class="text-sm">You will lose any unsaved changes.</span>
                        </div>

                        <div class="flex justify-between md:justify-end md:gap-4">
                            <MuvalButton
                                type="button"
                                quaternary
                                borderless
                                @click="closeInsuranceConfirmDialog()"
                            >
                                Cancel
                            </MuvalButton>
                            <MuvalButton
                                primary
                                alternate
                                @click="closeInsuranceConfirmDialog('confirm')"
                            >
                                Yes I'm sure
                            </MuvalButton>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        </ModalDialog>

        <ModalDialog
            v-model="isInsurancePaymentDialogOpen"
            maxWidth="max-w-[525px]"
        >
            <InsuranceCheckoutForm />
        </ModalDialog>

        <ModalDialog
            v-model="isCompanyInfoDialogOpen"
            maxWidth="max-w-5xl mt-20 md:mt-0"
        >
            <div :key="results?.selectedCompany?.id">
                <span class="font-medium">{{ results?.selectedCompany?.name }}</span>
                <div class="py-4 text-muval-gray-2">
                    {{ results?.selectedCompany?.description }}
                </div>
                <div class="h-[75vh]">
                    <iframe
                        class="h-full w-full"
                        :src="`https://www.muval.com.au/moving-companies/${results?.selectedCompany?.profile_slug}?noNav=1`"
                    />
                </div>
            </div>
        </ModalDialog>

        <ModalDialog
            v-model="isReadonlyMatchListDialogOpen"
            maxWidth="max-w-4xl mt-20 md:mt-0"
        >
            <div class="flex flex-col gap-4">
                <div class="-mt-4 flex items-center justify-between">
                    <span class="font-medium">Other options</span>
                    <button
                        type="button"
                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                        @click="closeReadonlyMatchListDialog()"
                    >
                        <span class="sr-only">Close panel</span>
                        <XMarkIcon
                            class="h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <MatchList :readonly="true" />
                <ModalDialog
                    v-model="isReadonlyCompanyInfoDialogOpen"
                    maxWidth="max-w-5xl mt-20 md:mt-0"
                >
                    <div :key="results?.selectedCompany?.id">
                        <span class="font-medium">{{ results?.selectedCompany?.name }}</span>
                        <div class="py-4 text-muval-gray-2">
                            {{ results?.selectedCompany?.description }}
                        </div>
                        <div class="h-[75vh]">
                            <iframe
                                class="h-full w-full"
                                :src="`https://www.muval.com.au/moving-companies/${results?.selectedCompany?.profile_slug}?noNav=1`"
                            />
                        </div>
                    </div>
                </ModalDialog>
            </div>
        </ModalDialog>

        <ModalDialog
            v-model="isTrustDialogOpen"
            maxWidth="max-w-3xl mt-20 md:mt-0"
        >
            <TrustModuleModal />
        </ModalDialog>

        <ModalDialog
            v-model="isTaxiboxDialogOpen"
            maxWidth="max-w-3xl mt-20 md:mt-0"
        >
            <TaxiboxModal />
        </ModalDialog>

        <ModalDialog
            v-model="isSelfyDialogOpen"
            maxWidth="max-w-3xl mt-20 md:mt-0"
        >
            <SelfyModal />
        </ModalDialog>
    </template>

    <!-- Modals below do NOT need session be ready -->
    <ModalDialog
        v-model="isVideoDialogOpen"
        transparent
        maxWidth="max-w-3xl mt-20 md:mt-0"
    >
        <VideoModal />
    </ModalDialog>

    <!-- Confirmation Modal -->
    <ModalDialog
        v-model="isConfirmDialogOpen"
        maxWidth="max-w-lg"
        class="z-50"
    >
        <div class="max-w-lg">
            <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-2">
                    <span class="font-medium">{{ confirmDialogLabel.title }}</span>

                    <span class="text-sm">{{ confirmDialogLabel.description }}</span>
                </div>

                <div class="flex justify-between md:justify-end md:gap-4">
                    <MuvalButton
                        type="button"
                        quaternary
                        borderless
                        @click="closeConfirmDialog('cancel')"
                    >
                        {{ confirmDialogLabel.cancel }}
                    </MuvalButton>
                    <MuvalButton
                        primary
                        alternate
                        @click="closeConfirmDialog('confirm')"
                    >
                        {{ confirmDialogLabel.confirm }}
                    </MuvalButton>
                </div>
            </div>
        </div>
    </ModalDialog>
</template>
