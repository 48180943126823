<template>
    <form
        class="grid gap-4"
        autocomplete="off"
    >
        <div class="grid grid-cols-2 gap-2">
            <MuvalInput
                vid="first_name"
                label="First name"
            />
            <MuvalInput
                vid="last_name"
                label="Last name"
            />
        </div>
        <MuvalInput
            vid="email"
            label="Email"
        />
        <div class="flex gap-2">
            <MuvalComboInput
                label="Phone"
                vid="phone_raw"
                infoType="+61"
            />
        </div>

        <div
            v-if="session.getOrderSensitive"
            class="flex flex-col gap-2 md:flex-row md:gap-8"
        >
            <div
                v-for="action in safeActions"
                :key="action.key"
            >
                <MuvalCheckbox
                    :vid="`order.${action.key}`"
                    :label="action.label"
                />
            </div>
        </div>

        <div>
            <MuvalTextArea
                class="col-span-4"
                label="Notes"
                :limit="600"
                vid="customer_notes"
                placeholder="Any additional information you’d like us to know?"
            />
        </div>

        <div class="flex justify-between md:justify-end md:gap-4">
            <MuvalButton
                type="button"
                quaternary
                borderless
                @click.prevent="cancelUpdate"
            >
                Cancel
            </MuvalButton>
            <MuvalButton
                :loading="isSubmitting"
                primary
                alternate
                @click="onSubmit"
            >
                Update
            </MuvalButton>
        </div>
    </form>
</template>

<script setup>
import MuvalInput from '../inputs/MuvalInput.vue';
import MuvalButton from '../button/MuvalButton.vue';
import { useForm, useFieldValue, useIsFieldDirty } from 'vee-validate';
import { useSessionStore } from '@/store';
import { openConfirmDialog } from '@/composables/dialog';
import MuvalComboInput from '@/components/inputs/MuvalComboInput.vue';
import MuvalTextArea from '@/components/inputs/MuvalTextArea.vue';
import emailSpellChecker from '@zootools/email-spell-checker';

const emit = defineEmits(['close:modal']);

const session = useSessionStore();
const { handleSubmit, isSubmitting, setErrors } = useForm({
    initialValues: {
        first_name: session.getCustomerFirstName,
        last_name: session.getCustomerLastName,
        email: session.getCustomerEmail,
        phone_raw: session.getCustomerPhone,
        customer_notes: session.getCustomerNotes,
        order: {
            safe_to_email: session.getOrder?.safe_to_email,
            safe_to_sms: session.getOrder?.safe_to_sms,
            safe_to_call: session.getOrder?.safe_to_call,
        },
    },
});

const safeActions = ref([
    { key: 'safe_to_email', label: 'Safe to Email' },
    { key: 'safe_to_sms', label: 'Safe to SMS' },
    { key: 'safe_to_call', label: 'Safe to Call' },
]);

const onSubmit = handleSubmit(async (values) => {
    if ((await checkEmail()) == false) {
        return;
    }
    try {
        const response = await window.axios.patch(`/customers/book/customer/${session.orderRef}`, values);

        console.log('Response', response.data.order);
        session.setSessionState({
            order: response.data.order,
        });

        emit('close:modal');
    } catch (error) {
        console.log('Error', error);
        setErrors(error.errors);
    }
});

function cancelUpdate() {
    emit('close:modal');
}

const customerEmail = useFieldValue('email');
const isEmailDirty = useIsFieldDirty('email');
async function checkEmail() {
    // only check email if it has been modified
    if (isEmailDirty.value) {
        const suggestion = emailSpellChecker.run({
            email: customerEmail.value,
        });
        if (suggestion) {
            if (
                (await openConfirmDialog({
                    title: 'Are you sure?',
                    description: `The email you've provided may contain a typo. Is ${customerEmail.value} correct?`,
                    confirm: 'Yes, correct',
                    cancel: 'Back',
                })) !== 'confirm'
            ) {
                return false;
            }
        }
    }

    return true;
}
</script>
