<template>
    <div class="min-h-[590px]">
        <div class="relative flex w-full flex-col gap-4">
            <div class="">
                <MuvalSearch
                    v-model:search="inventory.searchTerm"
                    class="col-span-1"
                    placeholder="Search items"
                    :loading="inventory.isFetchingSearch"
                />
            </div>

            <div
                v-if="!inventory.loading"
                class="flex w-full"
            >
                <div
                    v-if="!inventory.searchTerm?.length"
                    class="relative w-full md:min-w-[205px] md:max-w-[205px]"
                >
                    <div class="flex w-full flex-col md:hidden">
                        <button
                            class="mb-2 flex items-center justify-center gap-1 rounded-lg border bg-opacity-60 px-3 py-4 text-center text-xs font-medium hover:bg-gray-100/40"
                            @click="openCustomDialog"
                        >
                            Add custom item
                            <SvgIcon
                                class="h-3 w-3"
                                name="system-plus"
                            />
                        </button>
                        <template
                            v-for="category in inventory.categories"
                            :key="category.id"
                        >
                            <InventoryCategory
                                :category="category"
                                :inline="inline"
                                :mobile="true"
                            />
                        </template>
                    </div>
                    <div
                        v-if="!inventory?.searchTerm?.length"
                        class="sticky top-20 hidden md:flex md:w-full md:flex-col"
                    >
                        <button
                            class="mb-2 flex items-center justify-center gap-1 rounded-lg border bg-opacity-60 px-3 py-2 text-center text-xs font-medium hover:bg-gray-100/40"
                            @click="openCustomDialog"
                        >
                            Add custom item
                            <SvgIcon
                                class="h-3 w-3"
                                name="system-plus"
                            />
                        </button>
                        <template
                            v-for="category in inventory.categories"
                            :key="category.id"
                        >
                            <InventoryCategory
                                :category="category"
                                mode="tab"
                                :inline="inline"
                            />
                        </template>
                    </div>
                </div>

                <div
                    class="relative w-full px-4 md:flex"
                    :class="[inventory.searchTerm?.length ? 'flex' : 'hidden']"
                >
                    <div class="flex w-full flex-col px-5">
                        <template v-if="inventory.getOptions.length && !inventory.searchTerm?.length">
                            <div
                                :key="inventory.filterCategory"
                                class="grid grid-cols-1 gap-x-4"
                            >
                                <InventoryOption
                                    v-for="option in inventory.getOptions"
                                    :key="option.id"
                                    :item="option"
                                />
                            </div>
                        </template>

                        <template
                            v-else-if="
                                inventory.searchTerm?.length &&
                                inventory.searched?.length &&
                                !inventory.isFetchingSearch
                            "
                        >
                            <div class="grid grid-cols-1 gap-x-4">
                                <InventoryOption
                                    v-for="option in inventory.searched"
                                    :key="option"
                                    :item="option"
                                />
                            </div>
                        </template>

                        <template v-else-if="inventory.isFetchingSearch">
                            <div class="h-12 w-1/3 rounded-muval bg-muval-gray-5"></div>
                            <div class="flex flex-col gap-4">
                                <div
                                    v-for="index in 6"
                                    :key="index"
                                    class="h-12 w-full rounded-muval bg-muval-gray-5"
                                ></div>
                            </div>
                        </template>
                        <div
                            v-else-if="!inventory.loading && !inventory.isFetchingSearch && !inventory.searched.length"
                        >
                            <div
                                class="m-auto flex flex-col gap-4 rounded-muval-1 bg-muval-gray-5 p-6 text-center text-sm"
                            >
                                <span class="font-medium">No inventory options found.</span>
                                <span>
                                    Please try a different search or
                                    <a
                                        href="#"
                                        class="underline"
                                        @click.prevent="createCustomItem"
                                    >
                                        create a custom item
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="flex flex-col gap-4"
            >
                <div
                    v-for="index in 7"
                    :key="index"
                    class="h-16 w-full animate-pulse rounded-md bg-muval-gray-6"
                ></div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useInventoryStore } from '@/store';
import { MuvalSearch } from '@/inputs';
import InventoryCategory from '@/components/inputs/inventory/InventoryCategory.vue';
import InventoryOption from '@/components/inputs/inventory/InventoryOption.vue';
import { openCustomItemDialog, openInlineCustomItemDialog } from '@/composables/dialog';
import { useFieldArray } from 'vee-validate';

const props = defineProps({
    inline: {
        type: Boolean,
        default: false,
    },
});

function openCustomDialog() {
    if (props.inline) {
        openInlineCustomItemDialog();
    } else {
        openCustomItemDialog();
    }
}

const { push } = useFieldArray('move.inventory');

const inventory = useInventoryStore();

// hack to allow pushing to the field array from anywhere esp CustomInventoryForm
// where useFieldArray push was not working probably because at the time that modal existed in DOM, the form was not yet mounted
inventory.push = push;

function createCustomItem() {
    inventory.toggleCustomItemForm();

    if (props.inline) {
        openInlineCustomItemDialog();
    } else {
        openCustomItemDialog();
    }
}
</script>

<style scoped>
.noSelect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    background: #d0d0d0;
    border-radius: 6px;
}

.scrollbar-track-rounded-full::-webkit-scrollbar-track {
    border-radius: 6px;
}

.scrollbar-thumb-rounded-full::-webkit-scrollbar-track {
    border-radius: 6px;
}

.scrollbar-thumb-blue-50::-webkit-scrollbar-track {
    background: #c4c4c4;
}

.scrollbar-track-gray-50::-webkit-scrollbar-thumb {
    background: #6638b6;
}
</style>
