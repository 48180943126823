// Setup Pinia and reset store
import resetStore from './reset-store';
import { createPinia } from 'pinia';
export const pinia = createPinia();
pinia.use(resetStore);

// Stores
export { useAnalyticsStore } from './analytics';
export { useAppStore } from './app';
export { useCarTransportStore } from './carTransport';
export { useDiscountStore } from './discount';
export { useFeedbackStore } from './feedback';
export { useFormStore } from './form';
export { useInsuranceStore } from './insurance';
export { useInventoryStore } from './inventory';
export { useMoveStore } from './move';
export { usePaymentStore } from './payment';
export { usePetTransportStore } from './petTransport';
export { usePromotionStore } from './promotion';
export { useResultsStore } from './results';
export { useSelectedMatchStore } from './selectedMatch';
export { useSessionStore } from './session';
export { useSubscriptionStore } from './subscription';
