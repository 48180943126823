<template>
    <form
        class="flex flex-col gap-4"
        @submit="onSubmit"
    >
        <span class="font-header text-lg font-medium">Create an Item</span>
        <div class="form-row">
            <MuvalInput
                data-cy="custom-inventory-name"
                vid="name"
                label="Name your item"
                class="w-full max-w-lg"
            />
        </div>

        <div class="flex flex-col gap-1">
            <div class="form-titled-item">
                <span class="font-header">Calculate dimensions</span>
                <div class="flex flex-col gap-4">
                    <MuvalInput
                        data-cy="custom-inventory-length"
                        vid="length"
                        inputType="number"
                        pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        label="Length"
                        infoType="cm"
                    />
                    <MuvalInput
                        data-cy="custom-inventory-width"
                        vid="width"
                        inputType="number"
                        pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        label="Width"
                        infoType="cm"
                    />
                    <MuvalInput
                        data-cy="custom-inventory-height"
                        vid="height"
                        inputType="number"
                        pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        label="Height"
                        infoType="cm"
                    />
                    <div class="mt-2 flex flex-col gap-2 font-header text-sm">
                        <span>
                            Item m
                            <sup>3</sup>
                            calculation
                        </span>
                        <span class="text-base font-medium">
                            {{ Number.isNaN(customInventoryVolume) ? '0.00' : customInventoryVolume }}m&sup3;
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="minVolume"
                class="flex w-full"
            >
                <span class="ml-auto text-xs text-muval-gray-3">
                    Volume is less that 0.01m so will save as minimum of 0.01m&sup3;
                </span>
            </div>
        </div>
        <div class="flex justify-between gap-4">
            <MuvalButton
                borderless
                tertiary
                type="button"
                class="-mx-4"
                @click.prevent="closeDialog"
            >
                Cancel
            </MuvalButton>

            <div class="flex gap-2">
                <button
                    v-show="quantity > 0"
                    type="button"
                    class="my-auto h-10 w-10 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
                    @click.prevent="removeQty"
                >
                    <SvgIcon
                        class="m-auto h-3 w-3 cursor-pointer"
                        name="system-minus"
                    />
                </button>

                <input
                    v-show="quantity > 0"
                    type="numeric"
                    class="mx-auto my-auto h-10 w-10 rounded-full border px-2 text-center outline-none"
                    :class="[quantity > 99 ? 'text-xxs' : 'text-sm']"
                    :value="quantity"
                    @focus="isFocused = true"
                    @input="handleInput($event)"
                    @blur="handleBlur($event)"
                />

                <button
                    type="button"
                    class="my-auto h-10 w-10 rounded-full bg-gray-200 hover:bg-gray-300"
                    @click.prevent="addQty"
                >
                    <SvgIcon
                        class="m-auto h-3 w-3"
                        name="system-plus"
                    />
                </button>
                <MuvalButton
                    data-cy="custom-inventory-create"
                    alternate
                    :loading="isSubmitting"
                    @click.stop
                >
                    Add item/s
                </MuvalButton>
            </div>
        </div>
    </form>
</template>

<script setup>
import { useInventoryStore } from '@/store';
import { useForm } from 'vee-validate';
import { MuvalInput } from '@/inputs';
import MuvalButton from '@/components/button/MuvalButton.vue';
import { closeCustomItemDialog, closeInlineCustomItemDialog } from '@/composables/dialog';

const props = defineProps({
    inline: {
        type: Boolean,
        default: false,
    },
});

const isFocused = ref(false);
const quantity = ref(1);

const addQty = () => {
    if (isNaN(quantity.value)) {
        quantity.value = 1;
    } else {
        quantity.value++;
    }
};

const removeQty = () => {
    if (quantity.value > 0) {
        quantity.value--;
    }
};

function handleInput(event) {
    // updateQty no matter what but if 0 then dont as it would remove the input
    if (event.target.value == 0) {
        return;
    }
    quantity.value = parseInt(event.target.value);
}

function handleBlur(event) {
    quantity.value = parseInt(event.target.value);
}

const inventory = useInventoryStore();

function calculateVolume(length, width, height) {
    let volume = toFixed((length * width * height) / 1000000, 2);

    if (isNaN(volume) || volume < 0.01) {
        return 0.01;
    } else {
        return volume;
    }
}

const minVolume = computed(() => {
    if (values['length'] && values.width && values.height) {
        return customInventoryVolume.value <= 0.01 ? true : false;
    } else {
        return 0;
    }
});

const customInventoryVolume = computed(() => {
    if (values['length'] && values.width && values.height) {
        return calculateVolume(values['length'], values.width, values.height);
    } else {
        return 0;
    }
});

function newCustomInventory() {
    return {
        name: '',
        length: null,
        width: null,
        height: null,
    };
}

const { handleSubmit, isSubmitting, resetForm, values, setErrors } = useForm({
    initialValues: {
        ...newCustomInventory(),
    },
});

const closeDialog = () => {
    if (props.inline) {
        closeInlineCustomItemDialog();
    } else {
        closeCustomItemDialog();
    }
};

const onSubmit = handleSubmit(async (values) => {
    console.log('Handling submit', values);
    let payload = {
        name: values.name,
        length: values['length'],
        width: values.width,
        height: values.height,
    };

    try {
        const option = await inventory.createCustomOptionItem({
            name: payload.name,
            length: payload['length'],
            width: payload.width,
            height: payload.height,
            volume: calculateVolume(payload['length'], payload['width'], payload['height']),
            qty: quantity.value,
        });

        console.log('Created custom inventory option', {
            inventory_option_id: option.id,
            qty: quantity.value,
        });

        // add to inventory
        inventory.push({
            inventory_option_id: option.id,
            qty: quantity.value,
        });

        resetForm({ values: { ...newCustomInventory() } });

        closeDialog();

        // set category filter to CUSTOM
        inventory.setCategoryFilter(1, false);

        // scroll to top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    } catch (error) {
        console.log('Error creating custom inventory', error);
        setErrors(error.errors);
    }
});

// https://stackoverflow.com/questions/10015027/javascript-tofixed-not-rounding
function toFixed(num, precision) {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}
</script>
