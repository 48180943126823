<template>
    <div
        class="mx-auto flex w-full max-w-[540px] px-3 md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px]"
    >
        <div class="flex w-full">
            <div class="relative flex w-full justify-between space-x-0 md:space-x-6">
                <div
                    class="w-full lg:w-2/3"
                    :class="app.devMode ? 'bg-red-100' : ''"
                >
                    <slot />
                </div>
                <!-- Needed to hold same padding -->
                <div class="hidden w-0 md:w-1/3 md:min-w-[300px] lg:block">
                    <slot name="meta" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAppStore } from '@/store';

const app = useAppStore();
</script>
