<template>
    <div class="flex flex-col gap-4">
        <div class="mx-auto my-6 flex h-[111px] w-[111px] shrink-0 items-center rounded-xl bg-muval-gray-1/10">
            <SvgIcon name="selfy-match-icon" />
        </div>
        <h1 class="text-center text-2xl font-medium">Selfy Self Pack Containers</h1>
        <p class="mb-6 text-center text-sm text-muval-gray-1">
            Thank you! Our team will reach out to you shortly to confirm your custom quote.
        </p>
    </div>
</template>
