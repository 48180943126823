<template>
    <Disclosure
        v-if="match"
        v-slot="{ open }"
        as="div"
        class="relative flex max-w-[853px] flex-col overflow-hidden rounded-lg bg-white shadow hover:overflow-visible"
    >
        <!-- Global Card -->
        <DisclosureButton
            class="group flex w-full flex-col"
            :disabled="!isAccordionActive"
        >
            <button
                class="flex w-full flex-col"
                :class="canSelectMatch ? 'cursor-pointer' : 'cursor-auto'"
                @click="handleMatchSelect"
            >
                <div
                    v-if="
                        !session.hasConfirmed &&
                        (((match?.isCustom || match?.isTender) && match?.expires_at && expiryCountdown.days <= 7) ||
                            match?.matchable_type == 'trip')
                    "
                    class="-mb-1 flex gap-2 px-3 pb-0 pt-4 text-xs"
                >
                    <div
                        v-if="match?.isCustom || match?.isTender"
                        class="flex items-center gap-1.5 rounded bg-[#FAEBE2] p-1 px-2"
                        :class="
                            expiryCountdown.days <= 3 ?
                                'bg-muval-red-3 text-muval-red-2'
                            :   'bg-muval-amber text-muval-brown-3'
                        "
                    >
                        <SvgIcon
                            class="h-4 w-4"
                            name="system-limited-time"
                        />
                        <div class="font-medium leading-tight">
                            <span v-if="expiryCountdown.days >= 1">
                                {{ expiryCountdown.days }} day<span v-if="expiryCountdown.days > 1">s</span> left
                            </span>
                            <span v-else-if="expiryCountdown.hours >= 1">
                                {{ expiryCountdown.hours }} hour<span v-if="expiryCountdown.hours > 1">s</span> left
                            </span>
                            <span v-else-if="expiryCountdown.minutes >= 1">
                                {{ expiryCountdown.minutes }} minute<span v-if="expiryCountdown.minutes > 1">s</span>
                                left
                            </span>
                            <span v-else>&lt;1 minute left</span>
                        </div>
                    </div>
                    <div
                        v-else-if="match.matchable_type == 'trip'"
                        class="flex items-center gap-1.5 rounded bg-muval-amber p-1 px-2 text-muval-brown-3"
                    >
                        <SvgIcon
                            class="h-4 w-4"
                            name="system-limited-time"
                        />
                        <span class="font-medium leading-tight"> Price nearly gone </span>
                    </div>
                </div>
                <div
                    v-else-if="match?.isCustom || match?.isTender || match?.isPremium || match?.isTaxibox"
                    class="-mb-1 flex gap-2 px-3 pb-0 pt-4 text-xs"
                >
                    <div
                        v-if="match?.isCustom || match?.isTender || match?.isTaxibox"
                        class="flex items-center gap-1.5 rounded bg-[#FAEBE2] p-1 px-2"
                    >
                        <span class="font-medium leading-tight text-[#FF5c00]">Muval exclusive price</span>
                    </div>
                    <div
                        v-if="match?.isPremium"
                        class="flex items-center gap-1.5 rounded bg-muval-purple-2 p-1 px-2"
                    >
                        <span class="font-medium leading-tight text-muval-purple-1">Premium</span>
                    </div>
                </div>

                <div class="flex w-full flex-col gap-4 px-3 py-4 md:flex-row">
                    <div class="flex w-full flex-wrap items-center justify-start gap-x-11 gap-y-6">
                        <!-- Company Info -->

                        <MuvexpressMatchDetails
                            v-if="match.isMuvexpress"
                            v-bind="{ match: match }"
                        />

                        <SelfyMatchDetails
                            v-else-if="match.isSelfy"
                            v-bind="{ match: match }"
                        />

                        <TaxiboxMatchDetails
                            v-else-if="match.isTaxibox"
                            v-bind="{ match: match }"
                        />

                        <MatchCompanyInfo
                            v-else
                            v-bind="{ match: match, readonly: readonly }"
                        />

                        <MatchInfo
                            v-if="!match.isAnonymous && match.company && match.company.metric"
                            class="ml-4.5 flex lg:hidden xl:hidden 2xl:flex"
                            v-bind="{
                                header: match.company.metric.header,
                                description: match.company.metric.description,
                            }"
                        />

                        <MatchInfo
                            v-if="match.isLocal && match.isVariable && !match.isMuvexpress"
                            class="flex md:ml-auto"
                            v-bind="{
                                header: `${match.travel_time} mins travel`,
                            }"
                            text-right
                        />

                        <!-- Dates -->
                        <MatchDates
                            v-if="match.isFixed && !match.isTaxibox && !match.isSelfy"
                            class="flex w-fit"
                            v-bind="{ match: match, moveJob: moveJob }"
                        />
                    </div>
                    <div
                        class="flex w-full flex-col items-center justify-center gap-2 md:w-fit"
                        :class="[readonly ? 'my-auto' : '']"
                    >
                        <component
                            :is="readonly && hasOnlyOneOption ? 'div' : MuvalButton"
                            v-if="((!session.hasConfirmed && !isReviewPage) || readonly) && !isCallbackOnly"
                            :data-testid="`select-match-id-${match.id}`"
                            alternate
                            :tertiary="!hasOnlyOneOption && !match.isLocal"
                            :class="{
                                '!border-brand !text-brand': !hasOnlyOneOption && !match.isLocal,
                                'flex justify-center': readonly && hasOnlyOneOption,
                            }"
                            class="w-full min-w-[120px] md:w-fit"
                            :loading="
                                (!match.isFixed && match.selecting) ||
                                (match.isMuvexpress && match.selecting) ||
                                (hasOnlyOneOption && !match.hasOptions && match.selecting) ||
                                (hasOnlyOneOption && match.hasOptions && match.options[0].selecting)
                            "
                            @click="handleMatchSelect"
                        >
                            <div class="flex items-center gap-1">
                                <div class="flex flex-row items-baseline gap-1 md:flex-col">
                                    <span
                                        v-if="
                                            match.isFixed &&
                                            match.isInterstate &&
                                            !hasOnlyOneOption &&
                                            !match.isMuvexpress
                                        "
                                        class="text-[10px] font-medium leading-none opacity-70"
                                    >
                                        From
                                    </span>
                                    <span
                                        v-if="match.isFixed"
                                        class="text-sm font-medium leading-none"
                                    >
                                        {{ match.estimated_total?.formatted }}
                                    </span>
                                    <span
                                        v-else-if="match.isVariable"
                                        class="text-sm font-medium leading-none"
                                    >
                                        {{ match.price_per_unit?.formatted }}
                                        <span class="text-[10px] leading-none opacity-70">/ 30mins</span>
                                    </span>
                                </div>
                                <template v-if="isAccordionActive">
                                    <template v-if="!open">
                                        <SvgIcon
                                            name="system-chevron-down"
                                            class="h-4 w-4 opacity-50"
                                        />
                                    </template>
                                    <template v-else>
                                        <SvgIcon
                                            name="system-chevron-up"
                                            class="h-4 w-4 opacity-50"
                                        />
                                    </template>
                                </template>
                                <template
                                    v-else-if="
                                        (hasOnlyOneOption && !match.isVariable && !readonly) ||
                                        (match.isMuvexpress && !readonly)
                                    "
                                >
                                    <SvgIcon
                                        name="system-chevron-right"
                                        class="h-4 w-4 opacity-50"
                                    />
                                </template>
                            </div>
                        </component>
                        <div
                            v-if="isCallbackOnly && !session.hasConfirmed"
                            class="items-top 2xl:items-top flex w-full gap-2 md:block md:w-fit 2xl:flex 2xl:gap-2"
                        >
                            <div>
                                <div
                                    class="ml-auto mr-1 w-20 border-r border-gray-200 pr-3 text-right md:mb-1 md:mr-0 md:w-fit md:border-r-0 md:pb-3 md:pr-2 2xl:mb-0 2xl:mr-1 2xl:border-r 2xl:pb-0 2xl:pr-3"
                                >
                                    <div
                                        v-if="match.isSelfy"
                                        class="text-right text-xs font-medium text-[#9B9B9B]"
                                    >
                                        From
                                    </div>
                                    <div class="text-right text-sm md:flex md:gap-2">
                                        <span class="hidden line-through decoration-[#E5162A] decoration-2 md:inline">
                                            {{ match.matchable?.retail_price?.formatted?.replace(/\.00$/, '') }}
                                        </span>
                                        <span class="font-medium">
                                            {{ match.estimated_total?.formatted?.replace(/\.00$/, '') }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="match.isTaxibox"
                                        class="text-right text-xs font-medium text-[#9B9B9B]"
                                    >
                                        Total Price
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow">
                                <MuvalButton
                                    tertiary
                                    class="w-full font-medium md:min-w-[120px]"
                                    :class="{
                                        'border-muval-gray-1': match.hasCallback && !match.selecting,
                                    }"
                                    :loading="match.selecting"
                                    @click.stop="handleCallbackRequest"
                                >
                                    <div
                                        v-if="match.hasCallback"
                                        class="flex items-center gap-1.5"
                                    >
                                        Callback requested
                                        <div
                                            class="flex h-4 w-4 items-center justify-center rounded-full bg-muval-gray-1"
                                        >
                                            <SvgIcon
                                                class="text-white"
                                                name="system-check"
                                            />
                                        </div>
                                    </div>
                                    <template v-else>Request callback</template>
                                </MuvalButton>
                            </div>
                        </div>
                        <MuvalButton
                            v-else-if="moveJob?.isPending"
                            tertiary
                            class="pointer-events-none ml-auto w-full font-medium"
                        >
                            Pending
                        </MuvalButton>
                        <MuvalButton
                            v-else-if="showOtherOptionsButton || moveJob?.isCancelled || moveJob?.isDeclined"
                            tertiary
                            class="pointer-events-auto w-full font-medium"
                            @click.stop.prevent="
                                moveJob?.isCancelled || moveJob?.isDeclined ? showMatches() : routeTo()
                            "
                        >
                            Other options
                        </MuvalButton>
                    </div>
                </div>
            </button>
        </DisclosureButton>

        <!-- Match Details -->
        <ButterAccordionPanelAnimation>
            <DisclosurePanel class="border-t">
                <div class="p-4 px-8">
                    <!-- Interstate Match -->
                    <!-- only show if options greater than 1 -->
                    <MatchDetailsInterstate
                        v-if="match.isInterstate && match.options && match?.options?.length > 1"
                        v-bind="{ options: match.options, readonly: readonly }"
                    />

                    <!-- Local Match -->
                    <MatchDetailsLocal
                        v-if="match.isLocal"
                        v-bind="{ match: match, readonly: readonly }"
                    />
                </div>
            </DisclosurePanel>
        </ButterAccordionPanelAnimation>
    </Disclosure>
</template>

<script setup lang="ts">
// Stores
import { useSessionStore, useResultsStore } from '@/store';
import MuvalButton from '@/components/button/MuvalButton.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import type { MoveMatch, MoveJob } from '@/types';
import { openReadonlyMatchListDialog, openSelfyDialog, openTaxiboxDialog } from '@/composables/dialog';
import useDayJs from '@/plugins/useDayJs';

interface Props {
    match: MoveMatch;
    moveJob?: MoveJob;
    readonly?: Boolean;
}

const props = defineProps<Props>();

const session = useSessionStore();
const results = useResultsStore();
const router = useRouter();

function showMatches() {
    results.prefetch();
    openReadonlyMatchListDialog();
}

const hasOnlyOneOption = computed(() => {
    if (props.match?.isLocal) {
        if (props.match?.hasOptions && props.match?.options?.length >= 1) {
            if (props.match?.options?.length > 1) {
                console.log('match has more than one option');
                console.log(props.match?.company?.name);
            }

            return true;
        }
    }

    if (props.match?.hasOptions && props.match?.options?.length > 1) {
        return false;
    } else {
        return true;
    }
});

const canSelectMatch = computed(() => {
    // If the user is on the review page, prevent selecting the match again.
    if (isReviewPage.value || router.currentRoute.value.name === 'Confirmed') {
        return false;
    }

    if (props.match.selecting) {
        return false;
    }

    if (!hasOnlyOneOption.value && !props.match.isMuvexpress) {
        return false;
    }

    return true;
});

const isCallbackOnly = computed(() => {
    if (props.match.isTaxibox) {
        return true;
    }

    if (props.match.isSelfy && !props.match.isBookable) {
        return true;
    }

    return false;
});

const handleMatchSelect = async () => {
    console.log('Can select match', canSelectMatch.value);

    if (!canSelectMatch.value || props.readonly) {
        return;
    }

    if (isCallbackOnly.value) {
        await handleCallbackRequest();
        return;
    }

    if (props.match.hasOptions) {
        console.log('props.match.options[0]');
        results.selectMatch(props.match.options[0]);
    } else {
        console.log('props.match');
        results.selectMatch(props.match);
    }
};

const handleCallbackRequest = async () => {
    if (props.match.isTaxibox) {
        if (!props.match.hasCallback) {
            results.requestCallback({ match: props.match, notify: false });
        }

        openTaxiboxDialog();
    }

    if (props.match.isSelfy) {
        if (!props.match.hasCallback) {
            results.requestCallback({ match: props.match, notify: false });
        }

        openSelfyDialog();
    }
};

/*
 * Returns a bool if the accordion mode should be active.
 */
const isAccordionActive = computed(() => {
    if (isReviewPage.value || (router.currentRoute.value.name === 'Confirmed' && !props.readonly)) {
        return false;
    }

    if (props.match.isMuvexpress) {
        return false;
    }

    if (props.match.isFixed && !hasOnlyOneOption.value) {
        return true;
    }

    return false;
});

const showOtherOptionsButton = computed(() => {
    if (isReviewPage.value && session.hasOtherOptions && !isCallbackOnly.value) {
        return true;
    } else {
        return false;
    }
});

const dayjs = useDayJs();
// Expiry countdown timer
const expiryCountdown = ref({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    timeLeft: 0,
});

const timerInterval = ref(0);

function updateCountdown() {
    if (session.hasConfirmed) {
        return;
    }

    if (!props.match.expires_at) {
        return;
    }

    const now = dayjs();
    const expires = dayjs.utc(props.match.expires_at).tz('Australia/Brisbane', true);
    const diff = expires.diff(now);

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    const timeLeft = diff > 0 ? diff : 0;
    expiryCountdown.value = { days, hours, minutes, seconds, timeLeft };

    // Stop timer if countdown is over
    if (!diff || diff <= 0) {
        if (timerInterval.value) {
            clearInterval(timerInterval.value);
            timerInterval.value = 0;
        }

        expiryCountdown.value = { days: 0, hours: 0, minutes: 0, seconds: 0, timeLeft: 0 };

        return;
    }
}

onMounted(() => {
    if (!session.hasConfirmed && props.match.expires_at) {
        updateCountdown();

        timerInterval.value = setInterval(updateCountdown, 1000);
    }
});

const isReviewPage = computed(() => {
    return router.currentRoute.value.name === 'Review';
});

/*
 * Routes to the correct results page based on the match type.
 */
const routeTo = () => {
    if (props.match.isMuvexpress || props.match.isCustom) {
        return router.push('/results/recommended');
    } else {
        return router.push('/results/organic');
    }
};
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}
</style>
