<template>
    <div class="flex flex-col gap-4">
        <div class="mx-auto my-6 h-[111px] w-[111px] shrink-0">
            <SvgIcon
                class="h-full w-full"
                name="taxibox-hero-image"
            />
        </div>
        <h1 class="text-center text-2xl font-medium">
            Your TAXIBOX booking request
            <br />
            has been submitted!
        </h1>
        <p class="text-center text-sm text-muval-gray-1">
            A Muval team member will contact you shortly to confirm your booking.
        </p>
        <div class="mx-auto my-6 h-[27px] w-[106px] shrink-0">
            <SvgIcon
                class="h-full w-full"
                name="taxibox-logo"
            />
        </div>
        <p class="mb-2 text-center">
            <a
                href="https://www.taxibox.com.au/privacy-policy/"
                target="_blank"
                class="text-xs text-[#9B9B9B] underline"
            >
                T&amp;Cs apply
            </a>
        </p>
    </div>
</template>
