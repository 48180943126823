<template>
    <div class="flex flex-col">
        <div class="relative flex flex-col gap-2">
            <div class="flex gap-4">
                <div class="flex flex-col gap-1">
                    <h6 class="font-body">Name</h6>
                    <h6 class="font-body">Email</h6>
                    <h6 class="font-body">Phone</h6>
                </div>
                <div class="flex flex-col gap-1">
                    <span>{{ session.getCustomerFullName }}</span>
                    <span>{{ session.getCustomerEmail }}</span>
                    <span>
                        <span class="text-muval-gray-3">+61</span>
                        {{ session.getCustomerPhone }}
                    </span>
                </div>
            </div>

            <EditSummaryButton @click="customerEdit = true">Edit details</EditSummaryButton>
        </div>

        <MuvalModal
            v-model="customerEdit"
            :closeOnOutside="false"
            title="Update your details"
            rounded
        >
            <EditCustomer @close:modal="customerEdit = false" />
        </MuvalModal>
    </div>
</template>

<script setup>
import EditCustomer from '@/components/forms/EditCustomer.vue';
import MuvalModal from '@/components/MuvalModal.vue';
import { useSessionStore } from '@/store';

const session = useSessionStore();
const customerEdit = ref(false);

// const hasNotes = computed(() => {
//     return (
//         session.getCustomerNotes ||
//         session.getCustomQuoteNotes ||
//         session.getPickupNotes ||
//         session.getDeliveryNotes
//     );
// });
</script>
