<template>
    <div class="flex w-full items-center justify-between gap-2">
        <template v-if="props.isCustom">
            <Field
                :name="`inventory.${indexOfField}.inventory_option_label`"
                type="text"
                class="h-7 w-6/12 rounded-muval-1 border-muval-gray-4 text-sm focus:border-focus focus:outline-none focus:ring-0"
            />
        </template>
        <div
            v-else
            class="w-6/12"
        >
            {{ item.name }}
        </div>

        <div class="flex h-7 w-3/12 items-center gap-0.5">
            <button
                v-show="field?.value?.qty > 0"
                type="button"
                class="my-auto h-10 w-10 rounded-full bg-[#E5E7EB] hover:bg-[#D1D5DB] md:h-6 md:w-6"
                @click.stop.prevent="removeQty"
            >
                <SvgIcon
                    class="m-auto h-3 w-3 cursor-pointer"
                    name="system-minus"
                />
            </button>

            <input
                v-show="field?.value?.qty > 0"
                type="numeric"
                class="mx-auto my-auto h-10 w-10 rounded-full border px-2 text-center outline-none focus:border-black md:h-6 md:w-10"
                :class="[field?.value?.qty > 99 ? 'text-xxs' : 'text-sm']"
                :value="field?.value?.qty"
                @focus="isFocused = true"
                @input="handleInput($event)"
                @click.stop
                @blur="handleBlur($event)"
            />

            <button
                v-if="field"
                type="button"
                class="my-auto ml-auto h-10 w-10 rounded-full bg-[#E5E7EB] group-hover:bg-[#D1D5DB] md:h-6 md:w-6"
                @click.stop.prevent="addQty"
            >
                <SvgIcon
                    class="m-auto h-3 w-3"
                    name="system-plus"
                />
            </button>
            <button
                v-else
                class="my-auto ml-auto whitespace-nowrap rounded-full bg-[#E5E7EB] px-2 py-1 text-xs group-hover:bg-[#D1D5DB]"
                @click.stop.prevent="addQty"
            >
                Add item
            </button>
        </div>

        <div class="w-3/12">
            <MuvalCurrency
                v-if="field"
                :vid="`inventory.${indexOfField}.value`"
                class="!h-7 text-right"
            />
        </div>
    </div>
</template>

<script setup>
import { useFieldArray, Field } from 'vee-validate';
import MuvalCurrency from '@/components/inputs/MuvalCurrency.vue';

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    isCustom: {
        type: Boolean,
        default: false,
    },
    indexOfField: {
        type: Number,
        default: null,
    },
});

const isFocused = ref(false);

const { fields, update, remove, push } = useFieldArray('inventory');

function handleInput(event) {
    // updateQty no matter what but if 0 then dont as it would remove the input
    if (event.target.value == 0) {
        return;
    }
    updateQty(event);
}

function handleBlur(event) {
    updateQty(event);
}

function updateQty(event) {
    const newValue = parseInt(event.target.value);

    if (newValue > 250) {
        return;
    }

    if (!newValue) {
        remove(indexOfField.value);
        return;
    }

    update(indexOfField.value, {
        ...field.value.value,
        qty: newValue,
    });
    isFocused.value = false;
}

function removeQty() {
    if (field?.value?.value?.qty == 1) {
        remove(indexOfField.value);

        return;
    }

    const newValue = field.value.value.qty - 1;

    update(indexOfField.value, {
        ...field.value.value,
        qty: newValue,
    });
}

function addQty() {
    if (field?.value?.value?.qty >= 250) {
        return;
    }

    if (!field?.value?.value?.qty) {
        push({
            inventory_option_id: props.item.id,
            qty: 1,
            value: 0,
        });

        return;
    }

    const newValue = field.value.value.qty + 1;

    update(indexOfField.value, {
        ...field.value.value,
        qty: newValue,
    });
}

const indexOfField = computed(() => {
    return props.indexOfField ?? fields.value.findIndex((field) => field?.value?.inventory_option_id === props.item.id);
});

const field = computed(() => {
    return fields.value[indexOfField.value];
});
</script>
