<template>
    <div
        :class="[getStyles, borderStyle, backgroundStyle, { 'shadow-muval-1': !inline }, { 'items-center': true }]"
        class="container relative flex gap-2 rounded-muval-1 border-l-4 py-0.5 pl-2 antialiased"
    >
        <div class="absolute left-2.5 top-2 w-fit">
            <SvgIcon
                :name="getIcon"
                class="h-5 w-5"
            />
        </div>
        <p class="container py-1.5 pl-8 pr-4 text-sm font-normal text-muval-gray-1">
            <slot />
        </p>
    </div>
</template>
<script setup>
const props = defineProps({
    type: {
        type: String,
        required: false,
        default: 'danger',
        validator: (s) => ['info', 'alert', 'warning'].includes(s),
    },
    inline: {
        type: Boolean,
        required: false,
        default: false,
    },
    icon: {
        type: [String, null],
        required: false,
        default: null,
    },
});

const getIcon = computed(() => {
    if (props.icon) {
        return props.icon;
    }
    switch (props.type) {
        case 'warning':
            return 'system-warning';
        case 'alert':
            return 'system-information';
        default:
            return 'system-information';
    }
});

const getStyles = computed(() => {
    if (props.inline) {
        switch (props.type) {
            case 'info':
                return 'text-muval-information';
            case 'alert':
                return 'text-muval-amber';
            default:
                return 'text-error';
        }
    } else {
        switch (props.type) {
            case 'info':
                return 'text-muval-information ';
            case 'alert':
                return 'text-muval-amber ';
            default:
                return 'text-error ';
        }
    }
});

const borderStyle = computed(() => {
    switch (props.type) {
        case 'info':
            return 'border-muval-teal';
        case 'alert':
            return 'border-muval-amber';
        default:
            return 'border-error';
    }
});

const backgroundStyle = computed(() => {
    if (!props.inline) {
        return 'bg-white';
    }
    switch (props.type) {
        case 'info':
            return 'bg-muval-teal/10';
        case 'alert':
            return 'bg-muval-amber/10';
        default:
            return 'bg-error/10';
    }
});
</script>
