// todo remove when eslint does not complain about unused vars
export type Keyframe = any;
export type PropertyIndexedKeyframes = any;
export type KeyframeAnimationOptions = any;
// todo remove when eslint does not complain about unused vars

import type { AxiosStatic } from 'axios';

interface EchoType {
    channel: (name: string) => any; // Specify the actual type instead of `any` based on your Echo instance
}

declare global {
    interface Window {
        Echo: EchoType;
    }
}

declare global {
    interface Window {
        axios: AxiosStatic;
    }
}
export interface ConfirmDialogLabel {
    title: string;
    description?: string;
    confirm?: string;
    cancel?: string;
}

export type ConfirmDialogChoice = 'confirm' | 'cancel';

export interface ErrorDialogData {
    title: string;
    messages: string[];
    close: string;
}

export type MoveTime = {
    type: string;
    min: string;
    max: string;
    formatted: string;
};

export type MoveDates = {
    pickup: {
        min: string;
        max: string;
        formatted: string;
        time: MoveTime | null;
    };
    delivery: {
        min: string;
        max: string;
        formatted: string;
        time: MoveTime | null;
    };
};

export type Money = {
    formatted: string;
    amount: number;
    currency: string;
};

export enum ProductableType {
    MOVE = 'move',
    INSURANCE = 'insurance',
    CAR_TRANSPORT = 'car_transport',
    PET_TRANSPORT = 'pet_transport',
    ORDER_ADDON = 'order_addon',
}

export enum MoveType {
    LOCAL = 'LOCAL',
    INTERSTATE = 'INTERSTATE',
}

export enum MatchType {
    MUVEXPRESS = 'MUVEXPRESS',
    CUSTOM = 'CUSTOM',
    ORGANIC = 'ORGANIC',
    SELF_PACK = 'SELF_PACK',
}

export enum PricingType {
    FIXED = 'FIXED',
    VARIABLE = 'VARIABLE',
}

// export type Matchable = {
//   id: number;
// }

// export type MoveTaxiboxQuote = {
//   id: number;
//   box_count: number;
//   pickup_zone: number;
//   delivery_zone: number;
// }

export type MoveMatch = {
    id: string;
    order: number;
    isOption: boolean;
    match_type: MatchType;
    company_ref: string;
    company?: Company;
    isAnonymous: boolean;
    isCustom: boolean;
    isTender: boolean;
    isInterstate: boolean;
    isMuvexpress: boolean;
    isSelfPack: boolean;
    isSelfy: boolean;
    isTaxibox: boolean;
    isBookable: boolean;
    isLocal: boolean;
    isFixed: boolean;
    isVariable: boolean;
    isPremium: boolean;
    pricing_type: PricingType;
    hasCallback: boolean;
    move_id: string;
    move_type?: MoveType;
    price_per_unit?: Money;
    surcharge?: Money;
    extra_surcharges?: Money;
    dates: MoveDates;
    options: MoveMatchOption[];
    hasOptions: boolean;
    estimated_total: Money;
    breakdown?: Breakdown[];
    min_time?: number;
    travel_time: number;
    selecting?: boolean;
    disabled?: boolean;
    matchable?: any;
    expires_at?: string;
    matchable_type: string;
};

export type MoveMatchResults = {
    matches: MoveMatch[];
    meta: {
        removalist: {
            minimum_cost: Money;
            match_count: number;
        };
        self_pack: {
            minimum_cost: Money;
            match_count: number;
        };
    };
};

export type MoveJob = {
    id: string;
    move_type?: MoveType;
    company?: Company;
    status?: MoveStatus;
    isPending: boolean;
    isCancelled: boolean;
    isDeclined: boolean;
    dates?: MoveDates;
};

export enum MoveStatus {
    CANCELLED = 'CANCELLED',
    DECLINED = 'DECLINED',
    READY = 'READY',
    ACCEPTED = 'ACCEPTED',
    PENDING = 'PENDING',
}

export enum BreakdownType {
    ACCESS = 'ACCESS',
}

export enum MoveQuoteType {
    QUICK_QUOTE = 'QUICK_QUOTE',
    ACCURATE_QUOTE = 'ACCURATE_QUOTE',
    SPACE_KNOWN = 'SPACE_KNOWN',
}

export type Breakdown = {
    type: BreakdownType;
    label: string;
    value: string;
};

export type MoveMatchOption = {
    id: string;
    isOption: boolean;
    dates: MoveDates;
    breakdown: Breakdown[];
    price: Money;
    min_price?: Money;
    selecting: boolean;
    disabled: boolean;
    matchable_type: string;
};

export type Company = {
    id?: string;
    profile_slug?: string;
    name: string;
    description: string;
    logo: string;
    phone_hosted: string;
    email: string;
    review_rating: number;
    review_count: number;
    phone: string;
    metric: {
        header: string;
        description: string;
    };
};

export type CallbackRequest = {
    match: MoveMatch;
    notify: boolean;
};
