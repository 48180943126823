import { defineStore } from 'pinia';
import { useSessionStore } from '@/store';

export const useMoveStore = defineStore('move', () => {
    const sessionStore = useSessionStore();

    const exists = computed(() => !!getOrderItem.value);

    const getOrderItem = computed(() => {
        return (
            (sessionStore?.order as { items: any[] })?.items?.find((item) => item.productable_type === 'move') ?? null
        );
    });

    const getProduct = computed(() => {
        return getOrderItem.value?.product ?? null;
    });

    const isInterstate = computed(() => (getProduct.value as { type: string })?.type === 'INTERSTATE');

    const isCallbackRequested = computed(
        () => (sessionStore.order as { has_requested_move_callback: boolean })?.has_requested_move_callback,
    );

    const isSelfPackCallbackRequested = computed(
        () => (sessionStore.order as { has_requested_self_pack_callback: boolean })?.has_requested_self_pack_callback,
    );

    return {
        exists,
        getOrderItem,
        getProduct,
        isCallbackRequested,
        isSelfPackCallbackRequested,
        isInterstate,
    };
});
